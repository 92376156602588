<template>
	<b-container fluid id="bbook">
		<b-row>
			<b-col class="pl-0">
				<b-tabs vertical nav-wrapper-class="nav-vertical pr-0 pl-0" content-class="px-0 right-tab">
					<b-tab @click="$router.push('market-exposure')" :active="routeName === 'market-exposure'">
						<template #title>
							<font-awesome-icon :icon="['far', 'chart-pie']" class="font-medium-3" />
						</template>
						<MarketExposure v-if="routeName === 'market-exposure'" />
					</b-tab>
      
					<b-tab @click="$router.push('user-stats')" :active="routeName === 'user-stats'">
						<template #title>
							<font-awesome-icon :icon="['far', 'users']" class="font-medium-3"/>
						</template>
						<UserStats v-if="routeName === 'user-stats'" />
					</b-tab>
      
					<b-tab  @click="$router.push('company-p-l')" :active="routeName === 'company-p-l'">
						<template #title>
							<font-awesome-icon :icon="['fal', 'filter']" class="font-medium-3"/>
						</template>
						<CompanyPL v-if="routeName === 'company-p-l'" />
					</b-tab>

					<b-tab  @click="$router.push('deep-dive')" :active="routeName === 'deep-dive'">
						<template #title>
							<font-awesome-icon :icon="['far', 'cubes']" class="font-medium-3"/>
						</template>
						<DeepDive v-if="routeName === 'deep-dive'" />
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import MarketExposure from './marketExposure/Index.vue'
import UserStats from './userStats/Index.vue'
import CompanyPL from './companyPL/Index.vue'
import DeepDive from './deepDrive/Index.vue'
export default {
	components: {
		MarketExposure,
		UserStats,
		CompanyPL,
		DeepDive
	},
	data() {
		return {}
	},
	methods: {

	},
	computed: {
		routeName() {
			return this.$route.path.split('/')[2]
		}
	},
	mounted() {
	}
}
</script>
<style scoped>
#bbook >>> .nav-tabs .nav-link:after {
	background: none !important;
}
#bbook >>> .right-tab {
	max-width: calc(100% - 55px);
}
</style>