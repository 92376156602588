<template>
	<div>
		<b-form-group>
			<flat-pickr
				v-model="startDate"
				class="form-control"
				:config="{ maxDate: today}"
			/>
		</b-form-group>
	</div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
export default {
	components: {
		flatPickr
	},
	data() {
		return {
			startDate: '2021-09-05',
      today: moment().utc().format('Y-MM-DD'),
		}
	}
}
</script>

<style>

</style>