<template>
    <div class="company-p-l">
        <!-- Statistic Card -->
        <StatisticCards />
        <PLTable />

        <b-row>
            <b-col cols="6" class="pr-75">
                <ProfitLossChart />
            </b-col>
            <b-col cols="6" class="pl-75">
                <WithdrawableChart />
            </b-col>
            <b-col cols="6" class="pr-75">
                <DWChart />
            </b-col>
            <b-col cols="6" class="pl-75">
                <TradingVolumeChart />
            </b-col>
            <b-col cols="6">
                <EquityChart />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { formatMoney } from "@/helper";
import { BTabs, BTab, BDropdown, BDropdownItem, BDropdownDivider } from "bootstrap-vue";

import ProfitLossChart from "../companyPLCharts/ProfitLossChart";
import WithdrawableChart from "../companyPLCharts/WithdrawableChart.vue";
import EquityChart from "../companyPLCharts/EquityChart";
import DWChart from "../companyPLCharts/DWChart";
import TradingVolumeChart from "../companyPLCharts/TradingVolumeChart";
import StatisticCards from "./StatisticCards";
import PLTable from "./PLTable";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BTabs,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        BTab,
        ProfitLossChart,
        WithdrawableChart,
        DWChart,
        TradingVolumeChart,
        EquityChart,
        StatisticCards,
        PLTable,
    },

    directives: {
        Ripple,
    },

    data() {
        return {};
    },

    methods: {
        formatMoney,
    },

    created() {
        // this.$store.commit('app/SET_DATA_LIVE_TIME', moment().format(' HH:mm:ss - DD/MM/YYYY'))
    },
};
</script>
