<template>
    <div class="custom">
        <!-- <TestCalendar /> -->
        <!-- Account Analysis -->
        <account-analysis
            :isLoading="isLoading"
            :startDate="startDate"
            :endDate="endDate"
            @setStartDate="startDate = $event"
            @setEndDate="endDate = $event"
            @fetchAccountAnalysis="fetchAccountAnalysis"
            @viewAccountDetails="viewAccountDetails"
            @applyCustomTimeframe="applyCustomTimeframe"
        />

        <!-- Account Detail, PL -->
        <account-details
            v-if="show_account_details"
            ref="account_details"
            :current_item="current_item"
            :time_frame="account_details_time_frame"
            :parent_timeframe="timeframe"
            :parent_start_date="startDate"
            :parent_end_date="endDate"
            :flag="flag"
            @applyCustomTimeframeSingleItem="applyCustomTimeframeSingleItem"
            @set_time_frame="set_time_frame"
        />

        <!-- 2 Charts -->
        <div class="row  m-0 " v-if="show_account_details">
            <div class="col-6 pl-0 pr-75 pb-5">
                <ClientProfitHistoryCharts
                    v-if="show_account_details"
                    :current_item="current_item"
                    :time_frame="account_details_time_frame"
                    :dateStart="from_date"
                    :dateEnd="to_date"
                    :flag="flag"
                />
            </div>

            <div class="col-6 pl-75 pr-0 pb-5">
                <ClientEquityHistoryCharts
                    v-if="show_account_details"
                    :current_item="current_item"
                    :time_frame="account_details_time_frame"
                    :dateStart="from_date"
                    :dateEnd="to_date"
                    :flag="flag"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import ClientProfitHistoryCharts from "../deepDriveCharts/ClientProfitHistoryCharts";
import ClientEquityHistoryCharts from "../deepDriveCharts/ClientEquityHistoryCharts";
import AccountAnalysis from "./AccountAnalysis";
import AccountDetails from "./AccountDetails";
import TestCalendar from "./TestCalendar";
import axios from "@axios";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    components: {
        BTabs,
        BTab,
        ClientProfitHistoryCharts,
        ClientEquityHistoryCharts,
        AccountAnalysis,
        AccountDetails,
        TestCalendar,
    },

    data() {
        return {
            current_item: null,
            account_details_time_frame: "ThisMonth",
            show_account_details: false,
            startDate: null,
            endDate: null,
            from_date: null,
            to_date: null,
            flag: true,
            isLoading: false,
        };
    },

    channels: {
        MetricDataUpdateChannel: {
            connected() {
                console.log("Connected to the MetricDataUpdateChannel channel");
            },
            rejected() {
                console.log("Rejected to the MetricDataUpdateChannel channel");
            },
            received(data) {
                if (
                    data?.event_name == "trade.MetricDatum.before_destroy" &&
                    ["CurrentUserStatistics", "AccountAnalysis"].includes(data.payload.metric_definition_name)
                ) {
                    this.$store.dispatch("tableData/removeMetricDatum", data.payload);
                    return;
                }

                if (
                    data?.event_name == "trade.MetricDatum.after_create" &&
                    ["CurrentUserStatistics", "AccountAnalysis"].includes(data.payload.metric_definition_name)
                ) {
                    this.$store.dispatch("tableData/updateMetricDatum", data.payload);
                    return;
                }
                if (Array.isArray(data)) {
                    data.forEach((datum) => {
                        if (["CurrentUserStatistics", "AccountAnalysis"].includes(datum))
                            this.$store.dispatch("tableData/updateMetricDatum", datum);
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the MetricDataUpdateChannel channel");
            },
        },
    },

    mounted() {
        // this.$store.commit('app/SET_DATA_LIVE_TIME', moment().format(' HH:mm:ss - DD/MM/YYYY'))
        // this.$cable.subscribe({
        //     channel: "MetricDataUpdateChannel",
        // });
        // this.fetchAccountAnalysis()
    },

    computed: {
        ...mapGetters({
            timezoneOffset: "companySettings/timezoneOffset",
        }),
    },

    methods: {
        async fetchAccountAnalysis() {
            try {
                this.isLoading = true;
                await this.$store.dispatch("tableData/fetchCurrentUserStatistics");
                await this.$store.dispatch("tableData/fetchAccountAnalysis");

                // let res_aa = await axios.get(`${process.env.VUE_APP_API_URL}metric/AccountAnalysis`);
                let res_aa = await axios.get("/AccountAnalysis/data");

                // this.startDate = moment.parseZone(res_aa.data.properties.from_date.value).format("Y-MM-DD");
                // this.endDate = moment.parseZone(res_aa.data.properties.to_date.value).format("Y-MM-DD");

                //mockData
                this.startDate = moment.parseZone(res_aa.data[0].properties.from_date).format("Y-MM-DD");
                this.endDate = moment.parseZone(res_aa.data[0].properties.to_date).format("Y-MM-DD");
            } catch (err) {
                this.$notifyError("Error", "Failed to fetch account analysis!");
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        set_time_frame(e) {
            this.account_details_time_frame = e;
        },

        update_metric_data(datum) {
            if (
                datum.metric_definition_name &&
                ["CurrentUserStatistics", "AccountAnalysis"].includes(datum.metric_definition_name)
            ) {
                // console.log("update_metric_data", datum.id, this.current_item.id)
                if (
                    datum.id == this.current_item.id &&
                    this.from_date == this.startDate &&
                    this.to_date == this.endDate
                ) {
                    // console.log("update_metric_data", datum)
                    this.current_item = datum;
                }
            }
        },

        async applyCustomTimeframe(startDate, endDate) {
            this.isLoading = true;
            // this.$emit('viewAccountDetails');
            // this.user_statistics.splice(0)
            let from_date = parseInt(
                moment
                    .utc(startDate)
                    .startOf("day")
                    .unix()
            );
            let to_date = parseInt(
                moment
                    .utc(endDate)
                    .endOf("day")
                    .unix()
            );
            // console.log(moment.utc(startDate).startOf('day'), moment.utc(endDate).endOf('day'))
            axios
                .post(`${process.env.VUE_APP_API_URL}metric/AccountAnalysis/update_custom_timeframe`, {
                    from_date: from_date,
                    to_date: to_date,
                })
                .then(async (res) => {
                    await this.fetchAccountAnalysis();
                    this.$notifySuccess("Success", "Update custom timeframe successfully!");
                })
                .catch((err) => {
                    console.error(err.response);
                    this.$notifyError("Error", "Failed to update custom timeframe!");
                    this.isLoading = false;
                });
        },

        async applyCustomTimeframeSingleItem(startDate, endDate, afterSuccess, afterFailure) {
            // console.log("applyCustomTimeframe", startDate, endDate)
            this.from_date = startDate;
            this.to_date = endDate;
            let from_date = parseInt(
                moment
                    .utc(startDate)
                    .startOf("day")
                    .unix()
            );
            let to_date = parseInt(
                moment
                    .utc(endDate)
                    .endOf("day")
                    .unix()
            );
            await axios
                .post(`${process.env.VUE_APP_API_URL}metric/AccountAnalysis/update_custom_timeframe`, {
                    from_date: from_date,
                    to_date: to_date,
                    metric_datum_id: this.current_item.aa_id,
                })
                .then((res) => {
                    this.current_item.value = res.data.account.value;
                    // console.log("applyCustomTimeframe", this.current_item)
                    this.$refs.account_details.update_account_symbols(res.data.account_symbols);
                    if (afterSuccess) afterSuccess(res);
                })
                .catch((err) => {
                    console.error(err.response);
                    if (afterFailure) afterFailure(err);
                });
        },

        viewAccountDetails(item, timeframe) {
            if (!item) this.show_account_details = false;
            else {
                // console.log("viewAccountDetails", item)
                this.current_item = item;
                this.timeframe = timeframe;
                this.from_date = this.startDate;
                this.to_date = this.endDate;
                this.show_account_details = true;
                this.flag = !this.flag;
            }
        },
    },
};
</script>
