<template>
    <div class="custom">
        <!-- Market Exposure table -->
        <div>
            <market-exposure-table />
        </div>

        <div class="row">
            <!-- Platform deal table -->
            <div class="col-8 pr-2">
                <platform-open-positions-table />
            </div>

            <!-- Trading Account Exposure table -->
            <div class="col-4 pl-0">
                <trading-account-table />
            </div>
        </div>
    </div>
</template>
<script>
import MarketExposureTable from "./MarketExposureTable";
import PlatformOpenPositionsTable from "./PlatformOpenPositionsTable.vue";
import TradingAccountTable from "./TradingAccountTable";
import moment from "moment";

export default {
    components: {
        MarketExposureTable,
        PlatformOpenPositionsTable,
        TradingAccountTable,
    },
    data() {
        return {};
    },

    methods: {},

    channels: {
        MetricDataUpdateChannel: {
            connected() {
                console.log("Connected to the chat channel");
            },
            rejected() {
                console.log("Rejected to the chat channel");
            },
            received(data) {
                // console.log("avbcascasc")
                if (data?.event_name == "trade.MetricDatum.before_destroy") {
                    this.$store.dispatch("tableData/removeMetricDatum", data.payload);
                    return;
                }

                if (data?.event_name == "trade.MetricDatum.after_create") {
                    this.$store.dispatch("tableData/updateMetricDatum", data.payload);
                    return;
                }

                if (Array.isArray(data)) {
                    data.forEach((datum) => {
                        this.$store.dispatch("tableData/updateMetricDatum", datum);
                    });
                }
                this.$store.commit("app/SET_DATA_LIVE_TIME", moment().format(" HH:mm:ss - DD/MM/YYYY"));
            },
            disconnected() {
                console.log("Disconnected to the chat channel");
            },
        },
    },

    mounted() {
        //? mockData
        // this.$cable.subscribe({
        //     channel: "MetricDataUpdateChannel",
        // });
    },
};
</script>
