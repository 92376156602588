<template>
    <b-card class="company-chart" header-class="border-bottom py-75" body-class="pt-2 pb-0">
        <template #header>
            <div class="d-flex justify-content-between w-100 align-items-center">
                <div>
                    <span class="mb-0 font-small-4 font-weight-bolder text-heading-color">
                        Withdrawable
                    </span>
                </div>
                <div>
                    <b-dropdown :text="timeframe" variant="flat-dark" right toggle-class="py-50 text-heading-color">
                        <b-dropdown-item
                            v-for="tf in timeframes"
                            :key="tf.value"
                            @click="setDayStartEnd(tf.value), (timeframe = tf.label)"
                            class="font-small-3 text-heading-color"
                        >
                            {{ tf.label }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </template>

        <div class="company-chart__today-note" v-if="timeframe === 'Today'">
            Note: Today values are accumulated over hours
        </div>

        <div v-if="fetchingState === 'FETCHED'">
            <div class="d-flex w-100 justify-content-center" style="height: 20px">
                <div class="legend-withdrawable ml-1">
                    Withdrawable
                </div>
            </div>
            <!-- <VueApexCharts height="380" type="line" :options="chartOptions" :series="chartSeries"></VueApexCharts> -->
            <!-- mockData -->
            <VueApexCharts type="line" height="380" :options="chartOptions_mock" :series="series_mock"></VueApexCharts>
        </div>
        <div v-else>
            <div class="w-100 d-flex justify-content-center align-items-center" style="height: 415px">
                <b-spinner variant="primary"></b-spinner>
            </div>
        </div>
    </b-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import apexChartData from "@/views/charts-and-maps/charts/apex-chart/apexChartData";
import chartMixins from "./chartMixins";
import DigitalClock from "@/components/DigitalClock.vue";
export default {
    components: {
        VueApexCharts,
        DigitalClock,
    },
    mixins: [chartMixins],
    data() {
        return {
            fetchingState: "FETCHING",
            timeframe: "This Month",
            interval: null,

            //? mockData
            series_mock: [
                {
                    name: "Desktops",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
                    color: "#28c76f",
                },
            ],
            chartOptions_mock: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },

                grid: {
                    row: {
                        colors: ["#000000", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
                },
            },
        };
    },
    computed: {
        chartOptions() {
            return {
                ...apexChartData.accountEquityHistory.chartOptions,
                stroke: {
                    show: true,
                    colors: "#28C76F",
                    width: 2,
                    curve: "straight",
                    lineCap: "butt",
                },
            };
        },
    },
    methods: {
        async fetchAll() {
            try {
                this.fetchingState = "FETCHING";
                this.setDayStartEnd("ThisMonth");
                await Promise.all([
                    this.fetchYesterdayHistoryUsingMetricData(0, "Withdrawable"),

                    this.fetchTodayHistory(0, "CompanyTotalValues", "company", "Withdrawable"),

                    this.fetchCurrentValues("CompanyTotalValues", "company", ["Withdrawable"]),
                ]);
                this.fetchingState = "FETCHED";
            } catch (error) {
                this.fetchingState = "FAILED";
                console.error(error);
            }
        },
    },
    mounted() {
        this.series = [
            {
                name: "Withdrawable",
                chartType: "line",
            },
        ];
        this.fetchAll();
        this.interval = setInterval(() => {
            this.fetchCurrentValues("CompanyTotalValues", "company", ["Withdrawable"]);
        }, 10 * 1000);
    },
    destroyed() {
        clearInterval(this.interval);
    },
};
</script>
<style scoped>
.legend-withdrawable::before {
    content: " ";
    display: inline-block;
    height: 2px;
    width: 30px;
    transform: translateY(-3px);
    background: var(--primary);
}
.legend-withdrawable {
    color: var(--secondary);
    font-size: 0.8rem;
}
</style>
